import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAuditStatus } from '@/api/ceshi_xiugai/whole';
export default {
  name: 'DisplaySettingser',
  props: {
    displayArr: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    displayValue: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    Views: {
      type: String,
      required: false
    }
  },
  data: function data() {
    return {
      value: JSON.parse(JSON.stringify(this.displayValue)),
      name: this.Views,
      dialogTableVisible: false,
      draging: null,
      fullscreenLoading: false,
      page: 1,
      pagesize: 10,
      disp: ["客户名称", "联系电话", "学员来源", "缴费日期", "缴费用途", "客户种类"]
    };
  },
  watch: {
    value: function value(newV) {
      this.$emit('update:displayValue', newV);
    },
    dialogTableVisible: function dialogTableVisible(newV) {
      if (!newV) {
        localStorage.setItem(this.name, JSON.stringify(this.value));
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.value = JSON.parse(localStorage.getItem(this.name)) || this.displayValue;
  },
  methods: {
    handleClose: function handleClose(tag) {
      this.value.splice(this.value.indexOf(tag), 1);
    },
    reduction: function reduction() {
      this.value = JSON.parse(JSON.stringify(this.disp));
    },
    showEswt: function showEswt() {
      this.fullscreenLoading = true;
      this.value = JSON.parse(localStorage.getItem(this.name)) || this.displayValue;
      this.dialogTableVisible = true;
      this.fullscreenLoading = false;
    },
    dialogFork: function dialogFork() {
      var aaa = JSON.parse(localStorage.getItem(this.name));
      this.value = JSON.parse(localStorage.getItem(this.name)) || this.displayValue;
      this.$emit('dialogFork', aaa);
    },
    setUp: function setUp() {
      this.$emit('TableVisible', this.value);
    },
    // 开始拖放
    ondragstart: function ondragstart(event) {
      event.dataTransfer.setData("te", event.target.innerText); //设置拖放数据与类型，不能使用text，firefox会打开新tab
      this.draging = event.target;
    },
    ondragover: function ondragover(event) {
      event.preventDefault();
      var target = event.target;
      // 判断是不是span在进行拖动，防止将整个拖放容器拖动
      if (target.nodeName === "SPAN" && target !== this.draging) {
        if (this._index(this.draging) < this._index(target)) {
          target.parentNode.insertBefore(this.draging, target.nextSibling);
        } else {
          target.parentNode.insertBefore(this.draging, target);
        }
      }
    },
    // 拖放结束刷新列表
    ondragend: function ondragend(event) {
      var nodeData = [];
      var target = event.target;
      var nodeArr = target.parentNode.children;
      // 获取排序后的数据
      if (target.nodeName === "SPAN") {
        for (var i = 0; i < nodeArr.length; i++) {
          nodeData.push(nodeArr[i].innerText);
        }
        this.value = nodeData;
      }
    },
    // 拖放时进行排序
    _index: function _index(el) {
      var index = 0;
      if (!el || !el.parentNode) {
        return -1;
      }
      while (el && (el = el.previousElementSibling)) {
        index++;
      }
      return index;
    }
  }
};