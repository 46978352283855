var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "display-settings dib" },
    [
      _c(
        "el-button",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
              modifiers: { fullscreen: true, lock: true },
            },
          ],
          staticClass: "btnse",
          staticStyle: { color: "#1890FF" },
          on: { click: _vm.showEswt },
        },
        [
          _c("i", { staticClass: "el-icon-s-tools" }),
          _vm._v("\n        显示设置\n    "),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "显示设置", visible: _vm.dialogTableVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
            close: _vm.dialogFork,
          },
        },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            },
            _vm._l(_vm.displayArr, function (item) {
              return _c(
                "el-checkbox-button",
                { key: item, staticClass: "br4", attrs: { label: item } },
                [_vm._v(_vm._s(item))]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c("p", [_vm._v("显示顺序")]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "container" } },
            _vm._l(_vm.value, function (tag) {
              return _c(
                "el-tag",
                {
                  key: tag,
                  staticClass: "mr10 mb10",
                  attrs: {
                    closable: "",
                    effect: "plain",
                    type: "info",
                    draggable: "true",
                  },
                  on: {
                    close: function ($event) {
                      return _vm.handleClose(tag)
                    },
                  },
                },
                [_vm._v(_vm._s(tag))]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.reduction } }, [
                _vm._v("还原默认设置"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.setUp(), (_vm.dialogTableVisible = false)
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }